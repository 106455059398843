import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      },{
        path: '/download',
        component: () => import(/* webpackChunkName: "about" */ '../views/Download.vue')
      },{
        path: '/experienceFeedback',
        component: () => import(/* webpackChunkName: "about" */ '../views/ExperienceFeedback.vue')
      }
    ]
  },
  {
    path: '/ceshi',
    name: 'ceshi',
    component: () => import(/* webpackChunkName: "ceshi" */ '../views/ceshi.vue') // 更改 chunkName 以避免混淆
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
