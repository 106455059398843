import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { createI18n } from 'vue-i18n';
import en from '@/lang/en';
import zh from '@/lang/zh-CN';

// 创建 Vue 应用实例
const app = createApp(App);

// 注册 Element Plus
app.use(ElementPlus);

// 注册 Vuex store
app.use(store);

// 注册 Vue Router
app.use(router);

//需要修改的
// 注册全局变量
// app.config.globalProperties.ip = 'http://localhost';
// app.config.globalProperties.ip = 'http://47.116.115.210';
app.config.globalProperties.ip = 'http://47.89.229.58';

// 根据 IP 设置默认语言
let savedLocale = 'en';
if (app.config.globalProperties.ip === 'http://47.116.115.210') {
    savedLocale = 'zh-CN';
} else if (app.config.globalProperties.ip === 'http://47.89.229.58') {
    savedLocale = 'en';
} else if (app.config.globalProperties.ip === 'http://localhost'){
    savedLocale = localStorage.getItem('locale') || 'zh-CN';
}

// 创建 i18n 实例
const i18n = createI18n({
    locale: savedLocale, // 设置默认语言
    messages: {
        zh,
        en
    }
});

// 注册 i18n
app.use(i18n);

// 挂载应用
app.mount('#app');